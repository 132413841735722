<template>
<div>
  <div class="slogan" >
    <h4>
      {{$t("slogan")}}
    </h4>
    
  </div>
  <div class="s1">
    <div class="container">
        <markdown source="./jobs.md" :lang="$i18n.locale"></markdown>    
    </div>
  </div> 
</div> 
</template>
<script>
import Markdown from "@/components/Markdown.vue";

export default {
  components: {
    Markdown
  }
};
</script>
<style lang="less">
  
</style>
