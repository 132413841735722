



let marked = require('marked');
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';

@Component
export default class Home extends Vue {

@Prop({required: true})
public source!: string

@Prop({required: true})
public lang!:string

@Watch('lang')
onPropertyChanged(value: string, oldValue: string) {
   axios.get(this.source.replace(".md","."+ this.lang + ".md"))
   .then(response => {this.mdText = marked(response.data)})
   .catch(() => { this.mdText = '';  })
}

public mdText: string ='';
    mounted() {		
        marked.setOptions({  
            breaks: true            
        });
        axios.get(this.source.replace(".md","."+ this.lang + ".md")).then(response => {this.mdText = marked(response.data)})
    }
}
